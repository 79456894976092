import React from "react"
import { StoryGroup } from "components"

const LinkMap = {}

const stories = [
  {
    title: "React Animation Samples",
    postId: "fcb182a114c3",
  },
  {
    title: "React Audio Samples",
    postId: "8ea27cabf4d0",
  },
  {
    title: "React Color Samples",
    postId: "60a4dea34be5",
  },
  {
    title: "React Icon Samples",
    postId: "faffdebda887",
  },
  {
    title: "React Interaction Samples",
    postId: "67149b5da07e",
  },
  {
    title: "React Typography Samples",
    postId: "f9ccc7e23801",
  },
  {
    title: "React Video Samples",
    postId: "0393b6c2910d",
  },
  {
    title: "React i18n&l10n Samples",
    postId: "2e3f5345f3a1",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "React Foundation Samples",
  path: "react-foundation-samples",
}

const ReactFoundationSamples = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default ReactFoundationSamples
